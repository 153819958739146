<template>
  <svg class="loyalty-tag" width="20" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.25049 0.432731C8.71429 0.164956 9.28571 0.164956 9.74951 0.432731L16.9107 4.56727C17.3745 4.83504 17.6603 5.32991 17.6603 5.86546V14.1345C17.6603 14.6701 17.3745 15.165 16.9107 15.4327L9.74951 19.5673C9.28571 19.835 8.71429 19.835 8.25049 19.5673L1.08926 15.4327C0.625458 15.165 0.339746 14.6701 0.339746 14.1345V5.86546C0.339746 5.32991 0.625458 4.83504 1.08926 4.56727L8.25049 0.432731Z"
      :fill="`url(#tag-gradient-${compInstance?.uid})`"
    />
    <defs>
      <linearGradient
        :id="`tag-gradient-${compInstance?.uid}`"
        x1="9"
        y1="20"
        x2="9"
        y2="-1.20582e-07"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="colors.bottom" />
        <stop offset="1" :stop-color="colors.top" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script setup lang="ts">
  const props = defineProps<{
    topColor?: string;
    bottomColor?: string;
  }>();

  const compInstance = getCurrentInstance();
  const colors = computed(() => {
    if (!props.topColor || !props.bottomColor) return { top: 'transparent', bottom: 'transparent' };
    if (props.topColor && props.bottomColor) return { top: props.topColor, bottom: props.bottomColor };
    return { top: props.topColor || props.bottomColor, bottom: props.topColor || props.bottomColor };
  });
</script>

<style src="~/assets/styles/components/loyalty/tag.scss" lang="scss" />
